input:focus,
textarea:focus {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 0px black inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px rgb(255, 255, 255) inset !important;
}

.MuiOutlinedInput-root.Mui-error input:-webkit-autofill,
.MuiOutlinedInput-root.Mui-error input:-webkit-autofill:hover,
.MuiOutlinedInput-root.Mui-error input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px rgb(255, 245, 245) inset !important;
}

.is-dark input:-webkit-autofill,
.is-dark input:-webkit-autofill:focus,
.is-dark input:-webkit-autofill:hover,
.is-dark .MuiOutlinedInput-root.Mui-error input:-webkit-autofill,
.is-dark .MuiOutlinedInput-root.Mui-error input:-webkit-autofill:hover,
.is-dark .MuiOutlinedInput-root.Mui-error input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px rgb(42, 42, 42) inset !important;
}

.is-dark .MuiFormControl-root .MuiInputBase-formControl .MuiInputBase-input.Mui-disabled {
  background-color: rgb(42, 42, 42);
}
.is-dark
  .MuiFormControl-root
  .MuiInputBase-formControl
  .MuiInputBase-input.Mui-disabled
  + fieldset {
  border-color: rgb(42, 42, 42);
}
